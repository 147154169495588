import Image from "next/image"
import React from "react"
import { signIn } from "next-auth/react"

import "@gouvfr/dsfr/dist/component/card/card.min.css"

import Hospital from "@/components/svg/hospital.js"

const AuthentCard = (): JSX.Element => {
  return (
    <div className="w-full max-w-md px-4 py-2">
      <div className="fr-card" style={{ height: "100%" }}>
        <div className="fr-card__header">
          <div className="fr-card__img">
            <Hospital
              className="w-auto h-[13rem] mt-[4.5rem] mx-auto"
              alt="hôpital"
            />
          </div>
        </div>
        <div className="fr-card__body">
          <div className="fr-card__content" style={{ display: "flex", alignItems: "center" }}>
            <h3 className="fr-card__title">
              Etablissement - Ordre
            </h3>
            <p className="fr-card__desc">Connectez-vous à votre compte</p>
          </div>
          <div className="fr-card__footer" style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={() => signIn("onvs", { callbackUrl: "/private" })}
              className="fr-btn">
              <span className="mr-3">Se connecter via</span>
              <Image
                alt="Logo Plage"
                src="/logo_plage.png"
                width="70"
                height="30"
                aria-label="Logo Plage"
                role="img"
              />
            </button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default AuthentCard
