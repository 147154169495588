import { useRouter } from "next/router"
import { useState } from "react"

import "@gouvfr/dsfr/dist/component/card/card.min.css"

import DoctorsIcon from "@/components/svg/doctors"
import { startDeclarationUrl } from "@/components/wizard/stepFlows"
import Captchetat from "./Captcha"
import { createSecureToken } from "@/utils/secureTokenHelper"

const FreelanceCard = (): JSX.Element => {
  const router = useRouter();
  const [captchaOpen, setCaptchaOpen] = useState<boolean>(false);

  const goToStep0 = async () => {
    createSecureToken();
    router.push(startDeclarationUrl)
  }

  return (
    <div className="w-full max-w-md px-4 py-2">
      <div className="fr-card" style={{ height: "100%" }}>
        <div className="fr-card__header">
          <div className="fr-card__img">
            <DoctorsIcon className="mx-auto mt-5" />
          </div>
        </div>
        <div className="fr-card__body">
          <div className="fr-card__content">
            <h3 className="fr-card__title">
              Vous exercez en libéral ? (exercice de ville)
            </h3>
            <p className="fr-card__desc">Vous n&apos;avez pas besoin de compte
              pour remonter un signalement de violence.</p>
            <p className="fr-card__desc">Votre déclaration pourra être effectuée de manière anonyme.</p>
          </div>
          <div className="fr-card__footer" style={{ display: "flex", justifyContent: "center" }}>
            {!captchaOpen && <button className="fr-btn" type="submit" onClick={() => setCaptchaOpen(!captchaOpen)}>Déclarer</button>}
            {captchaOpen && <Captchetat styleName="captchaFR" onValidate={goToStep0} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FreelanceCard
