import { useStateMachine } from "little-state-machine"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"

import "@gouvfr/dsfr/dist/component/header/header.min.css"
import "@gouvfr/dsfr/dist/component/logo/logo.min.css"
import "@gouvfr/dsfr/dist/component/link/link.min.css"
import "@gouvfr/dsfr/dist/component/modal/modal.min.css"
import "@gouvfr/dsfr/dist/component/card/card.min.css"

import AuthentCard from "@/components/welcomePage/AuthentCard"
import Footer from "@/components/layout/Footer"
import FreelanceCard from "@/components/welcomePage/FreelanceCard"
import { formReducer } from "@/components/wizard/formReducer"
import HeadTitle from "@/components/HeadTitle"
import SkipLinks from "@/components/SkipLinks"
import fetcher from "@/utils/fetcher"
import { API_URL } from "@/utils/config"
import Modal from "@/components/commun/Modal"
import { signOut } from "next-auth/react"
import Header from "@/components/layout/Header"

type NotificationType = {
  id: string
  title: string
  message: string
  startDate: Date
  endDate: Date
}

const localStorageName = "ONVSNotif"

const IndexPage = () => {
  const [notif, setNotif] = useState<NotificationType | null>(null)
  const [openNotif, setOpenNotif] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const { action } = useStateMachine(formReducer)

  const router = useRouter()
  const { error } = router.query

  const getNotif = async (): Promise<NotificationType | null> => {
    try {
      const { data } = await fetcher(`${API_URL}/information`, {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      })
      if (data?.length) setNotif(data[0])
      return data?.[0]
    } catch (e) {
      console.error("Error Add Notif:", e)
      return null
    }
  }

  const checkNotifDisplay = (currentNotif: NotificationType) => {
    const storage = localStorage.getItem(localStorageName)
    const id = storage ? JSON.parse(storage)?.id : null
    const lastShown = storage ? JSON.parse(storage)?.lastShown : null

    const currentDate = new Date().toLocaleDateString()

    if (currentDate !== lastShown || currentNotif.id !== id) {
      setOpenNotif(true)
      localStorage.setItem(
        localStorageName,
        JSON.stringify({ id: currentNotif.id, lastShown: currentDate }),
      )
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const currentNotif = await getNotif()
      if (currentNotif) checkNotifDisplay(currentNotif)
    }
    fetchData()
  }, [])

  useEffect(() => {
    action({ event: { name: "RESET" } })
  }, [action])

  useEffect(() => {
    if (error) {
      setOpenError(true)
    }
  }, [error])

  return (
    <>
      <HeadTitle title="Accueil" />
      <SkipLinks />
      <Header />
      <main role="main" className="flex flex-col items-center">
        <Modal
          title={notif?.title || ""}
          text={notif?.message}
          labelPrimaryButton="Fermer"
          setOpenModal={() => setOpenNotif(false)}
          openModal={openNotif}
          noSndButton
          altStyle
        />
        <Modal
          title={"Erreur de connexion"}
          text={"Vous n'etes pas habilité à vous connecter à l'application."}
          labelPrimaryButton="Fermer"
          setOpenModal={() => {
            setOpenError(false)
            signOut({
              callbackUrl: "/api/auth/logout",
            })
          }}
          openModal={openError}
          noSndButton
        />
        <div className="flex justify-center flex-wrap w-full my-10">
          <AuthentCard />
          <FreelanceCard />
        </div>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
