import { useState, useEffect, FormEventHandler } from 'react';
import dynamic from "next/dynamic";

const Captcha = dynamic(
    () => import("reactjs-captcha").then((mod) => mod.Captcha),
    { ssr: false },
)

type ResultType = null | true | false;

interface CaptchetatProps {
    styleName: string,
    onValidate: () => any,
}

const Captchetat = ({ styleName, onValidate }: CaptchetatProps): JSX.Element => {
    const [result, setResult] = useState<ResultType>(null);
    let captchaRef: any;
    let ready = false;

    useEffect(() => {
        const initCaptcha = async () => {
            ready = true;
            const captchaSettings = (await import("reactjs-captcha")).captchaSettings;
            if (captchaSettings.set) {
                captchaSettings.set({
                    captchaEndpoint: '/api/captcha-endpoint',
                })
            }
        }
        if (captchaRef && !ready) initCaptcha();
    }, []);

    const handleOnSubmit: FormEventHandler = async (e) => {
        if (result !== null) setResult(null);

        e.preventDefault();

        // @ts-expect-error var botdetect will be added to window by the captcha package
        const instance = window?.botdetect?.getInstanceByStyleName(styleName);
        if (!instance) return;
        const postData = {
            code: instance.userInput.value.toUpperCase(),
            id: instance.captchaId,
        }

        const apiResult = await fetch("/api/submit", {
            method: "POST",
            body: JSON.stringify(postData),
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }).then((r) => {
            try {
                return r.json();
            } catch (e) {
                console.log(r.statusText);
                console.log(r.text());
                console.log(e);
            }
        });

        if (apiResult && apiResult.success === true) {
            setResult(true);
            onValidate();
        } else {
            setResult(false);
            captchaRef.retry();
        }
        e.preventDefault();
    }

    return (
        <section id="main-content">
            <form id="captchaForm" method="POST" onSubmit={handleOnSubmit} className="flex flex-col items-center">
                <p className='text-sm mb-2'>Veuillez compléter le Captcha pour accéder au formulaire</p>
                <Captcha
                    // @ts-expect-error captcha component has no type for props captchaStyleName
                    captchaStyleName={styleName}
                    ref={(instance: any) => { if (!captchaRef) captchaRef = instance }}
                />
                <input type="text" id="captchaFormulaireExtInput" className="fr-input fr-m-4w" style={{width: "200px"}} placeholder='CAPTCHA' />
                <div className="text-center mb-2">
                    <button type="submit" className="fr-btn">Valider</button>
                </div>
                {result === false && <p className='text-red-500'>Code invalide, veuillez recommencer</p>}
                {result === true && <p>Code valide, redirection en cours</p>}
            </form>
        </section>
    );
}

export default Captchetat;
